
.footer{
  border-top: 1px solid #E2E8F0;
  box-shadow:
          0 -10px 27px rgba(0, 0, 0, 0.05);
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  color: #333;
}

.footerbox {
  width: calc(33.33% - 20px);
  height: 180px;
  box-sizing: border-box;
  padding: 10px;
}

.footerbox a {
  display: block;
  color: #333;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 14px;
}

.footerbox a:hover {
  color: rgb(54, 17, 156);
}

.footerboxtitle {
  display: block;
  color: #333;
  text-decoration: none;
  margin-bottom: 15px;
  font-size: 16px;
}

.copyright {
  display: block;
  color: #747474;
  text-decoration: none;
  font-size: 10px;
  margin: auto;
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}

/* Add this to your style.css */

.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.social-icons img {
  display: flex;
  gap: 10px;
  margin-top: 2px;
}

.social-icons a {
  color: #333;
  transition: color 0.3s;
}

.social-icons a:hover {
  color: rgb(54, 17, 156);
}

.payment-icons {
  margin-left: 5px;
  margin-right: 5px;
}

.payment-icons img {
  margin-left: 3px;
  margin-right: 3px;
}


@media (max-width: 768px) {
  .footerbox {
    width: calc(50% - 20px);
  }
}

@media (max-width: 480px) {
  .footerbox {
    width: 100%;
  }
}


