.parent { 
  padding-bottom: 5px;
}

.forma {
  padding: 1rem 1rem;
  max-width: 500px;
  margin: auto;
}

.condText {
  padding: 1rem 1rem;
  max-width: 900px;
  margin: auto;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: #474747;
}

.idnpbutton {
  padding-top: 1rem;
}

.placeorderbutton {
  padding-bottom: 1rem;
  
}

.paybutton {
  padding-top: 1rem;
}

.boxresult {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin-top: 1rem;
  align-items: center;
  font-size: 16px;
}

.checkbox {
  padding-top: 1rem;
  font-size: medium;
}


.orderdetails {
  background: linear-gradient(120deg, #A7DFFA 0%, #E6F3FF 100%);
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  margin-bottom: 1rem;
}

.orderdetailsrow {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #333;
}

.orderdetailsrow b {
  font-weight: 600;
  color: #2c2c2c;
}


.mbfield {
  padding-bottom: 1rem;
}

.loading {
  padding-top: 1rem;
}

.inslogo {
  vertical-align: middle;
}

.paymentMethod {
  align-self: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  align-items: center;
  align-content: center;

}

.helpicon {
margin-right: -12px;
}

.popovermsg {
  background-color: black;
  color: rgb(233, 232, 232);
  opacity: 80%;
  padding: 10px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-size: 12px;
  max-width: 260px;
}

.cvtxt {
  align-self: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: red;
  font-weight: 600;
  font-size: 12px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-content {
  background-color: white;
  padding: 10px;
  width: calc(90vw - 20px);
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  .modal-content {
    width: 90%;
    max-width: unset;
  }
}

.popup-modal {
  display: flex;
  align-content: center;
  background-color: red;
}



@media (min-width: 769px) {
  .modal-content {
    align-self: center;
    max-width: 460px;
  }
}


.close-button {
  font-size: 16px;
  border: none;
  background: none;
  cursor: pointer;
  align-content: end;
}

.close-button:hover {
  color: red; /* Optional: Change the color on hover */
}


.loading {
  margin: 2rem;
}

.boxresult {
  margin-top: 2rem;
}
