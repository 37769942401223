.paymentStatus {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    font-family: 'Roboto', sans-serif;
    padding: 1rem;
    display: flex;
    flex-direction: column;
}

.transactionStatusIcon {
    color: blue;
    margin: auto;
    align-items: center;
}

.transactionStatusIconFailed {
    color: red;
    margin: auto;
    align-items: center;

}

.transactionStatusDetails {
    font-size: 12px;
    color: rgb(71, 71, 71);
    padding-top: 1rem;
    align-self: center;
}

.paymentStatusButton {
    padding-top: 1rem;
}


