.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, calc(100%/3 - 1rem)));
  grid-gap: 1rem;
  padding: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: rgb(41, 41, 41);
  align-items: stretch;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 1rem;
  position: relative;
  overflow: hidden;
  height: 175px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;

}

.card-content {
  flex-grow: 1;
}

.card-link {
  text-decoration: none;
  color: inherit;
  display: block;
}

.card h2 {
  margin: 0;
  padding: 0;
  font-size: 24px;
}

.card p {
  margin: 1rem 0;
}

.card button {
  background-color: #1976d2;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  padding: 8px 14px;
  cursor: pointer;
  align-self: flex-start;
  font-size: 14px;

}

.icon {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.15;
  background-repeat: no-repeat;
  background-position: right;
  background-size: 30%;
}

.icon-rca {
  background-image: url("./car-svgrepo-com.svg");
}

.icon-cartea-verde {
  background-image: url("./green-card.svg");
}

.icon-casco {
  background-image: url("./car-crash-svgrepo-com.svg");
}

.icon-medicala-pentru-calatorii {
  background-image: url("./travel-svgrepo-com.svg");
}

.icon-medicala-facultativa {
  background-image: url("./heart-pulse-svgrepo-com.svg");
}

.icon-ipoteca {
  background-image: url("./house-shield-svgrepo-com.svg");
}

/* Different background gradients for each card */
#rca {
  background: linear-gradient(135deg, #FFDAB9 0%, #FFA07A 100%);
}

#cartea-verde {
  background: linear-gradient(90deg, #B3F1E5 0%, #C4F0C5 100%);
}

#casco {
  background: linear-gradient(160deg, #C2E9E5 0%, #A1C4FD 100%);
}

#medicala-pentru-calatorii {
  background: linear-gradient(120deg, #FCC8E1 0%, #FCE9A9 100%);
}

#medicala-facultativa {
  background: linear-gradient(135deg, #A7DFFA 0%, #E6F3FF 100%);
}

#ipoteca {
  background: linear-gradient(45deg, #FFC3A0 0%, #FFAFBD 100%);
}

@media (max-width: 767px) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1080px) {
  .cards-container {
    grid-template-columns: repeat(auto-fit, minmax(calc(50% - 1rem), 2fr));
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
