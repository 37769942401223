.cta-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  flex-direction: column;
  background: linear-gradient(150deg, #FFD3E3 0%, #C8F8E8 100%);
  border-radius: 8px;
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.cta-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1200px;
}

.ctatext {
  text-align: center;
  margin-bottom: 1rem;
  width: 100%;
}

.ctatext h1 {
  margin-bottom: 1rem;
  font-size: 24px;
}

.submission-message {
  display: flex;
  
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.input-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.divinpunt {
  padding: 0 0.5rem;
  box-sizing: border-box;
  min-width: 340px;
  max-width: 33.33%;
  flex-basis: 33.33%;
  margin-bottom: 1rem;
}

.cta-button {
  background-color: #1976d2;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 14px;
  font-size: 14px;
  cursor: pointer;
  transition: 0.3s;
}

.cta-button:hover {
  background-color: #1565c0;
}

@media (max-width: 767px) {
  .cta-form-container {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
