.accordion-container {
  margin-right: 1.5rem;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  border-radius: 8px;
  overflow: hidden;
}

.MuiAccordion-root {
  border-radius: 8px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background: linear-gradient(135deg, #A7DFFA 0%, #E6F3FF 100%);
}

.MuiAccordion-root:not(:last-child) {
  margin-bottom: 1rem;
}

.MuiAccordion-root:before {
  content: none;
}

.MuiAccordionSummary-root {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  color: #fff;
}

.MuiAccordionDetails-root {
  padding: 1rem;
  background-color: rgba(255, 255, 255, 0.05);
}

.accordion-container h2 {
  font-size: 24px;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
}

.faq-title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 767px) {
  .accordion-container {
    margin-right: 1rem;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
}
