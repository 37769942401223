.hero-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  color: rgb(49, 49, 49);
}

.hero-text {
  flex: 1;
  text-align: center;
  padding: 20px;
}

.hero-text h1 {
  font-size: 55px;
  margin-bottom: 20px;
  color: rgb(32, 32, 32);
}

.hero-text p {
  font-size: 30px;
  color: rgb(32, 32, 32);
}

.image-container {
  min-width: 300px; /* Change this to whatever minimum width you want */
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.image-container img {
  width: 100%;
  height: auto;
  max-width: 500px;
}

