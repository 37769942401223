
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


li {
    list-style: none;
}

a {
    text-decoration: none;
}

.header{
    border-bottom: 1px solid #E2E8F0;
    box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    font-family: 'Roboto', sans-serif;
}

.hamburger {
    display: none;
}

.bar {
    display: block;
    width: 25px;
    height: 3px;
    margin: 5px auto;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    background-color: #101010;
}

.nav-menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.nav-item {
    margin-left: 2rem;
}

.nav-link{
    font-size: 16px;
    font-weight: 500;
    color: #475569;
}

.nav-link:hover{
    color: #482ff7;
}

.nav-logo {
    font-size: 2.1rem;
    font-weight: 500;
    color: #482ff7;
}

.nav-social-icons {
    display: none;
  }
  
  .nav-social-icons a {
    color: #333;
    transition: color 0.3s;
  }
  
  .nav-social-icons a:hover {
    color: rgb(54, 17, 156);
  }


  
  @media (max-width: 767px) {
    .nav-social-icons {
      display: flex;
      gap: 10px;
      align-self: center;
      align-content: center;
      align-items: center;
    }
  }


@media only screen and (max-width: 768px) {
    .nav-menu {
        position: fixed;
        z-index: 10;        
        left: -100%;
        top: 5rem;
        flex-direction: column;
        width: 95%;
        border-radius: 10px;
        text-align: center;
        transition: 0.3s;
        box-shadow:
            0 10px 27px rgba(0, 0, 0, 0.05);
        padding-bottom: 80px;
        background-image: url(./whitebg.jpg);
        background-color: #4e3737;
    }

    .nav-menu.active {
        left: 0;
        margin-left: 10px;
    }

    .nav-item {
        margin: 1.5rem 0;
    }

    .hamburger {
        display: block;
        cursor: pointer;
    }

    .hamburger.active .bar:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active .bar:nth-child(1) {
        -webkit-transform: translateY(8px) rotate(45deg);
        transform: translateY(8px) rotate(45deg);
    }

    .hamburger.active .bar:nth-child(3) {
        -webkit-transform: translateY(-8px) rotate(-45deg);
        transform: translateY(-8px) rotate(-45deg);
    }
}